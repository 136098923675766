<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useUser } from '/~/composables/user'
import QrModal from '../components/qr-modal.vue'

export default {
  name: 'qr-workspace-reserved',
  components: {
    BaseButton,
    QrModal,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    onHide: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { user } = useUser()

    return {
      user,
    }
  },
  computed: {
    details() {
      const startsAt = get(this.data, 'dates.start')
      const endsAt = get(this.data, 'dates.end')

      return `${formatDate('daymonthyear', startsAt)} - ${formatDate(
        'daymonthyear',
        endsAt
      )}`
    },
    building() {
      return get(this.data, 'building.name')
    },
    level() {
      return get(this.data, 'level.name')
    },
    asset() {
      return get(this.data, 'asset.name')
    },
  },
  methods: {
    onSubmit() {
      modal.hide()
      this.onHide()
      this.$router.push({ name: 'qr-connect' })
    },
  },
}
</script>

<template>
  <qr-modal>
    <template #title>
      <span>
        {{ title }}
      </span>
    </template>

    <div class="mx-5 flex flex-col">
      <b class="text-sm">Details</b>
      <span class="text-eonx-neutral-600">
        {{ details }}
      </span>
      <b class="mt-2.5 text-sm">Employee ID</b>
      <span class="text-eonx-neutral-600">
        {{ user.username || user.id }}
      </span>
      <b class="mt-2.5 text-sm">Checked-in to</b>
      <span class="text-eonx-neutral-600">Building {{ building }}</span>
      <span class="text-eonx-neutral-600">Level {{ level }}</span>
      <span class="text-eonx-neutral-600">Asset {{ asset }}</span>
    </div>
    <template #footer>
      <base-button class="mt-[30px] w-full" @click="onSubmit">
        Got it
      </base-button>
    </template>
  </qr-modal>
</template>
